


























































































































import AuthService from "@/services/AuthService";
import TimetableService from "@/services/TimetableService";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Request extends Vue {
  private hasError: boolean = false;
  private error: string = "";
  private email: string = "";
  private timetable: any[] = [];

  date(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  date2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  back() {
    router.push({ name: "home" });
  }

  send() {
    TimetableService.request(AuthService.username() || this.email).then(
      (response) => {}
    );
  }

  isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);

    if (this.isLoggedIn()) {
      TimetableService.registrations(AuthService.username()).then(
        (response) => (this.timetable = response.data)
      );
    }
  }
}
